<template>
  <div>
    <div style="width: 100%; display: flex; flex-direction: column;justify-content: center;align-items: center">
      <span style="font-size: 30px; font-weight: bold">小程序近15日登录情况</span>
      <div id="recentlyLogin" style="width: 100%; height: 400px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      recentlyLogin:{
        dateList: [],
        dataList: [],
      }
    }
  },

  mounted() {
    this.getRecentlyLoginData()
  },

  methods: {

    getRecentlyLoginData() {
      this.$api.data.recentlyLogin(15).then(res => {
        this.recentlyLogin = res
        this.drawRecentlyLogin()
      })
    },

    drawRecentlyLogin() {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let recentlyLogin = this.$echarts.init(document.getElementById("recentlyLogin"));
      // 指定图表的配置项和数据
      let option = {
        title: {
          //text: '近15日登录次数柱状图',
          /*textVerticalAlign:'center'*/
          textAlign:'auto',//整体（包括 text 和 subtext）的水平对齐
          textVerticalAlign:'auto',//整体（包括 text 和 subtext）的垂直对齐
          padding: [5, 550],//[5,10] | [ 5,6, 7, 8] ,标题内边距
          left:'auto',//'5' | '5%'，title 组件离容器左侧的距离
          right:'auto',//'title 组件离容器右侧的距离
          top:'auto',//title 组件离容器上侧的距离
          bottom:'auto',//title 组件离容器下侧的距离

        },
        xAxis: {
          type: 'category',
          data: this.recentlyLogin.dateList
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          show: true
        },
        series: [
          {
            name: '登录/次',
            data: this.recentlyLogin.dataList,
            type: 'bar',
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: 'black',
                    fontSize: 16
                  }
                }
              }
            },
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      recentlyLogin.setOption(option);
    },
  }
}
</script>

<style scoped>

</style>
